import React from 'react'

function SubmitButton({ text , onClick }) {
  return (
    <>
      <button type="submit" className="formbold-btn text-uppercase" onClick={onClick}>{text}</button>
    </>
  )
}

export default SubmitButton