import SolarForm from './Pages/SolarForm';
import LogoHeader from './Components/Common/LogoHeader';
import Footer from './Components/Common/Footer';

function App() {
  return (
    <>
      <div className='main-page'>
        <div className='main-container'>
          <LogoHeader /> 
          <SolarForm />
        </div>
      </div>
      <div >
        <Footer /> 
      </div >
    </>
  );
}

export default App;
