import React from 'react'
import Label from '../Common/Label'
import BarGraph from '../Common/BarGraph';
import FormHeader from '../Common/FormHeader';
import { NumberWithCommas } from '../../Utils/AddComma';

function SystemCost({ solarData, onBackButtonClick }) {

    const labels = ["Before Solar", "After Solar"]
    const data = [solarData.monthlybill, 24]

    const handleNull = (x) => isNaN(x) ? 0 : x

    function convertToYearsMonths(totalYears) {
        const years = Math.floor(totalYears);
        const decimalMonths = totalYears - years;
        const months = Math.floor(decimalMonths * 12);
        return `${years} years ${months} months`;
    }

    const paybackToolTip = "The payback period refers to how long it takes to start making a profit or recover the initial investment. If you're using a loan, your payback period could be immediate if there are no upfront costs, unless your down payment exceeds any initial incentives offered."
    const savingToolTip = "This is all savings and incentive value minus the cost of the system."
    const firstyearincentive = "This is first year savings and incentive value minus the cost of the system."

    return (
        <>
            <div className="formbold-main-wrapper bg-white shadow-custom rounded-3 pt-1 px-4 pb-5 my-22">

                <FormHeader heading={"Estimated System Cost Information"} onBackButtonClick={onBackButtonClick} showBackButton={true} />

                <div className='d-flex mb-5 row-gap-4'>

                    <div className='d-flex flex-column w-50 w-md-full p-0 row-gap-1'>
                        <Label type="label" text={"Estimated Total System and Installation Cost"} />
                        <Label type="value" customClass={"text-decoration-line-through"} text={`$ ${NumberWithCommas(handleNull(Math.round(solarData?.totalSystemCost + solarData?.totalSystemCost * 0.2)))}`} />
                        <span className='d-flex align-items-center gap-2'><Label type="value" text={`$ ${NumberWithCommas(handleNull(Math.round(solarData?.totalSystemCost)))}`} /><span className='after-price'>Discounted Price</span></span>

                        <Label type="label" text={"Estimated Incentives (Tax Credit)"} />
                        <Label type="value" customClass={"text-decoration-line-through"} text={`$ ${NumberWithCommas(handleNull(Math.round(solarData.incentives + solarData.incentives * 0.2)))}`} />
                        <span className='d-flex align-items-center gap-2'><Label type="value" text={`$ ${NumberWithCommas(handleNull(Math.round(solarData.incentives)))}`} /><span className='after-price'>Discounted Price</span></span>

                        <Label type="label" text={"Estimated Payback Period"} showToolTip={true} ToolTipText={paybackToolTip} />
                        {(solarData.paymentmethod != "loan") ?
                            <Label type="value" text={convertToYearsMonths(handleNull(solarData?.paybackPeriod)?.toFixed(2))} />
                            :
                            <Label type="value" text={"Instant"} />
                        }

                        {(solarData.paymentmethod !== "cash") ?
                            <>
                                <Label type="label" text={"Estimated Monthly Payment"} />
                                <Label type="value" text={"$ " + NumberWithCommas(handleNull(Math.round(solarData?.emi + solarData?.emi * 0.2)))} />
                                <Label type="value" text={"$ " + NumberWithCommas(handleNull(Math.round(solarData?.emi)))} />
                            </>
                            :
                            <></>
                        }

                        <Label type="label" text={"Estimated First Year Electric Bill Savings"} showToolTip={true} ToolTipText={firstyearincentive} />
                        <Label type="value" text={`$ ${NumberWithCommas(handleNull(Math.round((solarData.monthlybill - 24) * 12)))}`} />

                        <Label type="label" text={"Estimated Net Savings In 25 years"} showToolTip={true} ToolTipText={savingToolTip} />
                        <Label type="value" text={`$ ${NumberWithCommas(handleNull(Math.round(solarData.netSavings)))}`} />

                    </div>


                    <div className='d-flex flex-column w-50 w-md-full text-center'>

                        <Label type="label" text={"Estimated Monthly Electric Bill Comparison"} />
                        <BarGraph labels={labels} graphData={data} />

                    </div>

                </div>

                <div className='inputform-disclaimer'>
                    <p className='m-0 p-0'><span>*</span>This estimate is based on the information provided by the customer and is non-binding</p>
                </div>
            </div>
        </>)
}

export default SystemCost