import React from 'react'
import ToolTip from './ToolTip'

function Label({ type, text, showToolTip, ToolTipText, customClass }) {
    return (
        <>
            <label style={{
                color: type === "value" ? "#38393b" : undefined, fontWeight: type === "value" ? "bold" : undefined
            }} className={`summary-label ${customClass}`}>
                {text}
                {showToolTip && <ToolTip ToolTipText={ToolTipText} />}
            </label>

        </>
    )
}

export default Label