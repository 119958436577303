import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FooterHeader } from './FormHeader';
import Green360 from '../../Assets/Green360Solutions-Logo.png';


const Footer = () => {
  return (
    <>
      <section style={{ backgroundColor: '#ffffff', padding: '40px 0' }}>
        <Container style={{ width: '90%', margin: '0 auto' }}>
          <Row>
            <Col md={8}>
              <Row>
                <Col sm={5} className="mb-4">
                  <div className="d-flex flex-column align-items-center text-center p-4" style={{  backgroundColor: '#efefef', border: '1px solid #eaeaea', borderRadius: '8px', height: '100%' }}>
                    <div className="icon mb-2">
                      <i className="fa fa-smile-o fa-2x" style={{ color: '#1B4962' }}></i>
                    </div>
                    <h5 style={{ color: '#1B4962' }}>Worry-free Experience</h5>
                    <p style={{ fontSize:'12px'}}> Relax with our worry-free and comforting service that takes care of everything for you.</p>
                  </div>
                </Col>
                <Col sm={5} className="mb-4">
                  <div className="d-flex flex-column align-items-center text-center p-4" style={{ border: '1px solid #eaeaea', borderRadius: '8px', height: '100%' }}>
                    <div className="icon mb-2">
                      <i className="fa fa-check-square-o fa-2x" style={{ color: '#1B4962' }}></i>
                    </div>
                    <h5 style={{ color: '#1B4962' }}>Professional Approach</h5>
                    <p style={{ fontSize:'12px'}}>Discover our skilled and meticulous process, guaranteed to make your project a triumph.</p>
                  </div>
                </Col>
                <Col sm={5} className="mb-4">
                  <div className="d-flex flex-column align-items-center text-center p-4" style={{ border: '1px solid #eaeaea', borderRadius: '8px', height: '100%' }}>
                    <div className="icon mb-2">
                      <i className="fa fa-hard-hat fa-2x" style={{ color: '#1B4962' }}></i>
                    </div>
                    <h5 style={{ color: '#1B4962' }}>Safety First</h5>
                    <p style={{ fontSize:'12px'}}>Safety is our top concern, so we create a safe space for your comfort and assurance.</p>
                  </div>
                </Col>
                <Col sm={5} className="mb-4">
                  <div className="d-flex flex-column align-items-center text-center p-4" style={{ backgroundColor: '#efefef', border: '1px solid #eaeaea', borderRadius: '8px', height: '100%' }}>
                    <div className="icon mb-2">
                      <i className="fa fa-flag-checkered fa-2x" style={{ color: '#1B4962' }}></i>
                    </div>
                    <h5 style={{ color: '#1B4962' }}>15+ Years of Experience</h5>
                    <p style={{ fontSize:'12px'}}>With over 15 years of experience, we deliver excellence and contentment in every job we undertake.</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={3} className="d-flex flex-column justify-content-center">
              <h3 style={{ color: '#1B4962' }}>WHY CHOOSE US?</h3>
              <p style={{ fontSize:'12px'}}>Choose us for unmatched expertise, personalized solutions, and a commitment to eco-friendly practices that deliver both energy savings and stellar customer service. Your satisfaction is our priority.</p>
            </Col>
          </Row>
        </Container>
      </section>

      <footer style={{ backgroundColor: "#f2f3f2" }} className="pt-4">
        <Container fluid>
          <Row>
            <Col md={4}>
              <FooterHeader heading={""} />
              <ul className="list-unstyled p-4">
                <li className='text-center'><img src={Green360} alt='logo' className='h-75 w-75' /></li>
                <li className='text-center' style={{ color: '#1B4962', fontSize: '16px', fontWeight: '600' }}>License #987760</li>
                <li style={{ fontSize: '12px', fontWeight: '500', marginTop: '5%', textAlign: 'center' }}>FORMERLY KNOWN AS <b>ELITE SOLAR</b></li>
              </ul>
            </Col>
            <Col md={2}>
              <FooterHeader heading={"Services"} />
              <ul className="list-unstyled">
                <li>Solar Services</li>
                <li>HVAC Services</li>
                <li>Roofing Services</li>
                <li>Insulation Services</li>
                <li>Electrician Services</li>
              </ul>
            </Col>
            <Col md={2}>
              <FooterHeader heading={"Company"} />
              <ul className="list-unstyled">
                <li>About us</li>
                <li>Contact Us</li>
                <li>Terms Of Use</li>
                <li>Privacy Policy</li>
                <li>Sitemap</li>
              </ul>
            </Col>
            <Col md={4}>
              <FooterHeader heading={"Contact Info"} />
              <ul className="list-unstyled">
                <li>21241 Ventura Blvd Suite 153 Woodland Hills, CA 91364</li>
                <li>(855) 870 3999</li>
                <li>Info@Green360Solutions.com</li>
              </ul>
              <FooterHeader heading={"Office Hours"} />
              <ul className="list-unstyled">
                <li>Mon-Fri: 8AM - 6PM</li>
                <li>Saturday - Sunday: Closed</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <div className='d-flex justify-content-between flex-wrap footer-copyright'>
          <div>
            <ul className="list-unstyled">
              <li style={{ fontSize: '13px' }}>Copyright © 2023 Green360Solutions, All rights reserved. Powered by Yalla.Studio Digital Marketing Solutions </li>
            </ul>
          </div>
          <div>
            <ul className="list-unstyled d-flex gap-3">
              <li style={{ fontSize: '13px' }}> Privacy Policy </li>
              <li style={{ fontSize: '13px' }}> Terms Of Service </li>
              <li style={{ fontSize: '13px' }}> Cookies Settings </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
