import React from 'react'
import InputForm from './InputForm'
import CustomerDetails from './CustomerDetails'
import SolarOutput from './SolarOutput'
import SystemCost from './SystemCost'
import { useState, useRef } from 'react'
import { motion } from "framer-motion"
import DiscountModal from '../Common/Modal'

function Index() {

    const INITIAL_DATA = {
        consumption: 0,
        zipcode: '',
        solarhoursoptions: 'N',
        solarhours: 1,
        billoffset: 100,
        area: 0,
        monthlybill: "",
        typeofroof: 'Asphalt Shingles',
        roofpitch: 'flat (0°)',
        solarpanelwatts: '400',
        annualmilesofev: 0,
        bcd: 0,
        paymentmethod: "cash",
        inputtypeoptions: 'M',
        userannualconsumption: "",
        utilitycompany: 'LADWP'
    }

    const panelOutput = 400              // in watt
    const panelWidth = 1                 // in meters
    const panelLength = 1.99             // in meters
    const costOfUtility = 0.25           // in dollar
    const envFactor = 0.9               // in percent
    const pricePerWatt = 2.80            // in dollar
    const pricePerWattForLoan = 3.05     // in dollar
    const unitPriceOfEV = 0.32
    const noofmonthsforloan = 300
    const noofyearsforsavings = 25
    const annualincreaserate = 0.03       //increase rate in annual payments (used for calculating net savings)

    const steps = useRef()
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState(INITIAL_DATA);
    const [showLoader, setShowLoader] = useState(false);
    const [showModal, setIsShowModal] = useState(false)
    const [solarData, setSolarData] = useState({
        systemSize: 0,
        requiredPanels: 0,
        areaOccupied: 0,
        solarHours: 0,
        typeofroof: '',
        roofPitch: '',
        totalCost: 0,
        paybackPeriod: 0,
        incentives: 0,
        netSavings: 0,
        netCost: 0,
        emi: 0,
        systemSizeWithPanels: 0,
        interestAmount: 0,
        annualConsumption: 0,
        estimatedUnits: 0,
        monthlybill: 0,
        solarHoursMonthWise: [],
        solarProductionPerMonth: []
    });

    const [customerData, setCustomerData] = useState({
        fullname: '',
        email: '',
        address: '',
        phone: '',
        post: '',
        discountAvailed: false
    });

    const nextStep = () => {
        setCurrentStep(prev => prev + 1)
        steps.current?.scrollIntoView({ behavior: 'smooth' })
    };

    const prevStep = () => {
        setCurrentStep(prev => prev - 1);
        steps.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleFormSubmitInputForm = async () => {


        var solarHoursResponse = await calculateSolarHoursByZipCode()

        if (solarHoursResponse != null) {
            var solarHours = solarHoursResponse?.outputs?.avg_lat_tilt.annual
            var solarHoursMonthWise = Object.values(solarHoursResponse?.outputs?.avg_lat_tilt.monthly);
            solarHoursMonthWise = solarHoursMonthWise.map(e => e - 1.5)                                // Solar Hours Per Day
            solarHoursMonthWise[2] = solarHoursMonthWise[2] - 1.5
            solarHoursMonthWise[9] = solarHoursMonthWise[9] - 1
            var solarHoursPerMonth = calculteMonthlySolarHours(solarHoursMonthWise)  // Solar Hours Per Month
        }
        else {
            alert("Zip code not correct")
            return
        }

        if (solarHours != null) {
            let annualConsumption;
            let netCost;
            let emi;
            let totalSystemCost;
            let incentives;
            let interestAmount;
            let netCostWithInterest;
            let monthlybill;

            solarHours = solarHours - 1.5
            const annualunitpriceev = calculateAnnualUnitPriceEV();
            const annualunitpricebcd = calculateAnnualUnitPriceBCD();

            if (formData.inputtypeoptions === 'M') {
                monthlybill = formData.monthlybill;
                const avgNoOfUnits = calculateAvgNoOfUnits();
                annualConsumption = calculateAnnualConsumption(avgNoOfUnits, annualunitpriceev, annualunitpricebcd);
            } else {
                annualConsumption = parseFloat(formData.userannualconsumption) + (parseFloat(annualunitpriceev)) + (parseFloat(annualunitpricebcd));
                monthlybill = (annualConsumption * costOfUtility) / 12
            }

            const estimatedUnits = calculateEstimatedUnits(solarHours);
            const systemSize = calculateSystemSize(annualConsumption, estimatedUnits);

            const monthlySystemSize = calculateSystemSizeMonthWise(annualConsumption, solarHoursMonthWise)

            const requiredPanels = calculateRequiredPanels(systemSize);

            const solarProductionPerMonth = calculateSolarProductionPerMonth(solarHoursPerMonth, requiredPanels)

            const areaOccupied = calculateAreaOccupied(requiredPanels);
            const systemSizeWithPanels = calculateSystemSizeWithPanels(requiredPanels);

            if (formData.paymentmethod === "loan") {
                const interestRate = (5.99 / 100) / 12;
                totalSystemCost = calculateTotalSystemCostLoan(systemSizeWithPanels, annualunitpriceev, annualunitpricebcd);
                incentives = calculateIncentives(totalSystemCost);
                netCost = calculateNetCost(totalSystemCost, incentives);
                emi = calculateEmi(netCost, interestRate);
                netCostWithInterest = calculateNetCostFromEMI(emi)
                interestAmount = calulateInterestAmount(netCostWithInterest, netCost);
            } else {
                totalSystemCost = calculateTotalSystemCost(systemSizeWithPanels, annualunitpriceev, annualunitpricebcd);
                incentives = calculateIncentives(totalSystemCost);
                netCost = calculateNetCost(totalSystemCost, incentives);
                emi = 0;
                interestAmount = 0;
            }

            const paybackPeriod = calculatePaybackPeriod(netCost, annualConsumption);
            const netSavings = calculateNetSavings(annualConsumption, totalSystemCost);

            setSolarData({
                systemSize,
                requiredPanels,
                areaOccupied,
                solarHours,
                typeofroof: formData.typeofroof,
                roofPitch: formData.roofpitch,
                totalSystemCost,
                paybackPeriod,
                incentives,
                netSavings,
                netCost,
                annualunitpriceev,
                annualunitpricebcd,
                paymentmethod: formData.paymentmethod,
                emi,
                systemSizeWithPanels,
                interestAmount,
                annualConsumption,
                monthlybill,
                solarProductionPerMonth,
                estimatedUnits
            });
            nextStep();
            setTimeout(() => {
                setIsShowModal(true);
            }, 5000)
        }
        else {
            alert("Zip code not correct")
        }
    }

    const handleFormSubmitSolarOutput = () => {
        nextStep();
    }

    const handleFormSubmitCustomerDetails = () => {
        setShowLoader(true)

        setTimeout(() => {
            setShowLoader(false)
            nextStep();
        }, 3000);
    }

    const handleBackButtonClick = () => {
        prevStep();
    }

    const calculteMonthlySolarHours = (solarHoursMonthWise) => {
        solarHoursMonthWise = solarHoursMonthWise.map(e => (e * panelOutput * 30) / 1000)
        return solarHoursMonthWise
    }

    const calculateSolarProductionPerMonth = (solarHoursPerMonth, requiredPanels) => {
        var solarProductionPerMonth = solarHoursPerMonth.map(e => parseInt(e * requiredPanels))
        return solarProductionPerMonth
    }

    const calculateAvgNoOfUnits = () => {
        const avgNoOfUnits = formData.monthlybill / costOfUtility              // In kWh
        return avgNoOfUnits
    }

    const calculateAnnualConsumption = (avgnNoOfUnits, annualunitpriceev, annualunitpricebcd) => {
        const annualConsumption = (avgnNoOfUnits * 12) + (parseFloat(annualunitpriceev)) + (parseFloat(annualunitpricebcd))                       // In Kwh noofmonths = 12
        return annualConsumption
    }

    const calculateSystemSize = (annualConsumption, estimatedUnits) => {
        const systemSize = annualConsumption / estimatedUnits                   // Per year
        return systemSize
    }

    const calculateSystemSizeWithPanels = (requiredPanels) => {
        const systemSizeWithPanels = (Math.ceil(requiredPanels) * panelOutput) / 1000
        return systemSizeWithPanels
    }

    const calculateEstimatedUnits = (solarHours) => {
        const estimatedUnits = parseFloat(solarHours) * 365 * envFactor         // in kWh per year
        return estimatedUnits
    }

    const calculateRequiredPanels = (solarArraySize) => {
        const requiredPanels = solarArraySize * 1000 / panelOutput
        return requiredPanels
    }

    const calculateAreaOccupied = (requiredPanels) => {
        const areaOccupied = requiredPanels * panelWidth * panelLength
        return areaOccupied
    }

    const calculateSolarHoursByZipCode = async () => {
        const response = await fetch(`https://developer.nrel.gov/api/solar/solar_resource/v1.json?api_key=7GomqbzvqApG7JXawpHoQYoNwwnwvJpeTPkFgb94&address=${formData.zipcode}`);


        const solarHoursByZipCode = await response.json();

        if (solarHoursByZipCode.errors.length > 0 || solarHoursByZipCode?.outputs?.avg_lat_tilt == "no data") {
            return null
        }
        else {
            const avgSolarHours = solarHoursByZipCode?.outputs?.avg_lat_tilt.annual
            setFormData({ ...formData, solarhours: avgSolarHours });
            return solarHoursByZipCode
        }
    }

    const calculateSystemSizeMonthWise = (annualConsumption, solarHoursMonthWise) => {
        var monthlySystemSize = [];

        for (var i = 0; i < solarHoursMonthWise.length; i++) {
            var estimatedUnits = calculateEstimatedUnits(solarHoursMonthWise[i]);

            monthlySystemSize.push(estimatedUnits)
        }
        return monthlySystemSize
    }

    const calculateTotalSystemCost = (systemSize, annualmilesofev, annualunitpricebcd) => {
        var totalCost = ((systemSize * 1000)) * pricePerWatt
        return totalCost
    }

    const calculateTotalSystemCostLoan = (systemSize, annualmilesofev, annualunitpricebcd) => {
        var totalCost = ((systemSize * 1000)) * pricePerWattForLoan
        return totalCost
    }

    const calculatePaybackPeriod = (totalCost, annualConsumption) => {
        var paybackPeriod = ((totalCost) / costOfUtility) / annualConsumption
        return paybackPeriod
    }

    const calculateIncentives = (totalSystemCost) => {
        const incentives = 0.3 * totalSystemCost
        return incentives
    }

    function calculateNetSavings(annualConsumption, totalSystemCost) {
        let annualPayment = annualConsumption * costOfUtility;
        let totalSavings = 0;

        for (let year = 1; year <= noofyearsforsavings; year++) {
            const currentPayment = annualPayment * Math.pow(1 + annualincreaserate, year - 1);
            totalSavings += currentPayment;
        }
        totalSavings = totalSavings - totalSystemCost
        return totalSavings;
    }


    const calculateNetCost = (totalSystemCost, incentives) => {
        const netCost = totalSystemCost - incentives
        return netCost
    }

    const calculateNetCostFromEMI = (emi) => {
        const netCost = emi * noofmonthsforloan
        return netCost
    }

    function calculateEmi(netCost, interestRate) {
        const emi = netCost * (interestRate * Math.pow(1 + interestRate, noofmonthsforloan)) / (Math.pow(1 + interestRate, noofmonthsforloan) - 1);
        return emi;
    }

    const calculateAnnualUnitPriceEV = () => {
        const annualunitpriceofev = formData.annualmilesofev * unitPriceOfEV
        return annualunitpriceofev
    }

    const calculateAnnualUnitPriceBCD = () => {
        const annualunitpriceofbcd = formData.bcd * unitPriceOfEV
        return annualunitpriceofbcd
    }

    const calulateInterestAmount = (netCost, netCostWithoutInterest) => {
        const interestAmount = netCost - netCostWithoutInterest
        return interestAmount
    }

    return (
        <>

            <DiscountModal customerData={customerData} setCustomerData={setCustomerData} showModal={showModal} setIsShowModal={setIsShowModal} />

            <div className='container-sm' ref={steps}>
                <div className="step-buttons">
                    {[1, 2, 3, 4].map((step, index) => (
                        <>
                            <div>
                                <p className={`text-center step-number ${currentStep === step ? 'active' : ''}`}>Step {step}</p>
                                <button
                                    key={step}
                                    className={`step-button ${currentStep === step ? 'active' : ''}`}
                                >
                                    {step}
                                </button>
                            </div>
                            {
                                index < 3 &&
                                <div className='form-line'>
                                    <div className='line-container'>
                                        <div className="line"></div>
                                    </div>
                                </div>
                            }
                        </>

                    ))}
                </div>
                <div>
                    {currentStep === 1 &&
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1 }}
                        >
                            <div>
                                <InputForm formData={formData} setFormData={setFormData} onFormSubmit={handleFormSubmitInputForm} />
                            </div>
                        </motion.div>}

                    {currentStep === 2 &&
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div>
                                <SolarOutput solarData={solarData} onButtonClick={handleFormSubmitSolarOutput} onBackButtonClick={handleBackButtonClick} />
                            </div>
                        </motion.div>}

                    {currentStep === 3 &&
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div>
                                <CustomerDetails customerData={customerData} setCustomerData={setCustomerData} InputData={formData} solarOutput={solarData} onButtonClick={handleFormSubmitCustomerDetails} onBackButtonClick={handleBackButtonClick} />
                            </div>
                        </motion.div>}

                    {currentStep === 4 &&
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <div>
                                <SystemCost solarData={solarData} onBackButtonClick={handleBackButtonClick} />
                            </div>
                        </motion.div>}
                </div>
            </div >

            {showLoader &&
                <div className='loader-container-overlay'>
                    <div className="loader-container position-absolute ">
                        <p style={{ color: "black" }} className='position-absolute opacity-100'>Calculating Your Cost Estimate</p>
                    </div>
                    <div className="loader-dots loader-container position-absolute p-4">
                        <div className="spinner-grow" role="status">
                            <span className="sr-only position-absolute"></span>
                        </div>

                        <div className="spinner-grow" role="status">
                            <span className="sr-only position-absolute"></span>
                        </div>

                        <div className="spinner-grow" role="status">
                            <span className="sr-only"></span>
                        </div>

                    </div>
                </div>
            }

        </>
    )
}

export default Index