import React from 'react'
import { IoIosArrowRoundBack } from "react-icons/io";

export default function FormHeader({ heading, onBackButtonClick, showBackButton }) {
    return (
        <>
            <div style={{ color: '#1b4962' }} className='form-header h4 fw-bold my-4 text-capitalize' >
                {showBackButton && <span>
                    <button onClick={onBackButtonClick} className='btn form-header-button'><IoIosArrowRoundBack size={30}/></button>
                </span>}
                <span className='px-2'>
                    {heading}
                </span >
            </div >
        </>
    )
}


export function FooterHeader({ heading }) {
    return (
        <>
            <div style={{ color: '#1b4962', fontSize: '18px' }} className='footer-header fw-bold my-4' >
                {heading}
            </div>
        </>
    )
}
