import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputField, { InputFieldWithoutLabel } from './InputField';
import { useState } from 'react';
import Logo from "../../Assets/Green360Solutions-Logo.png"
import SolarAds from "../../Assets/solar-ads.jpg"

function DiscountModal({ customerData, setCustomerData, showModal, setIsShowModal }) {
    
    const availDiscount = (e) => {
        e.preventDefault()
        setIsShowModal(false)
        setCustomerData({ ...customerData, discountAvailed: true })
        console.log(customerData);
    }

    const closeModal = () => {
        setIsShowModal(false)
    }

    const handleInputChange = (e) => {
        setCustomerData({ ...customerData, [e.target.name]: e.target.value })
    }

    return (
        <div className=''>
            <div className=" ">

                <Modal show={showModal} onHide={closeModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Header closeButton>
                        <img src={Logo} height="40px" />
                    </Modal.Header>

                    <Modal.Body className='p-0 pb-3'>
                        <div className='mb-4'>
                            <img src={SolarAds} width="100%" height="auto" />
                        </div>
                        <form onSubmit={availDiscount}>
                            <div className='text-center'>
                                <p className='h1 fw-bold text-uppercase'>Get <span className='font-color fw-bolder'>20% off*</span> on your order</p>
                                <p style={{ marginBottom: "40px" }}>And gain access to exclusive sales, limited edition drops and more.</p>
                            </div>
                            <div className='d-flex w-full justify-content-center align-items-center gap-2'>
                                <div className=''>
                                    <InputFieldWithoutLabel type={"email"} name="email" placeholder="Enter your email address" value={customerData.email} onChange={handleInputChange} isRequired={true} />
                                </div>
                                <div>
                                    <Button type='submit' variant="success" className='py-2'>Avail Discount</Button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>

                    {/* <Modal.Footer>
                        </Modal.Footer> */}
                </Modal>
            </div>
        </div >
    );
}

export default DiscountModal;