import React from 'react'

function InputField({ label, type, name, id, placeholder, value, onChange, onBlur, isDisabled, maxLength, max, min, isRequired }) {
    return (
        <>
            <div>
                <label htmlFor={id} className="formbold-form-label">{label}{isRequired == true && <span className='fw-bold h6'> * </span>}</label>
                <input
                    type={type}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    className="formbold-form-input"
                    value={value}
                    onChange={onChange}
                    disabled={isDisabled | false}
                    maxLength={maxLength ? maxLength : ''}
                    max={max ? max : ''}
                    min={min ? min : ''}
                    onBlur={onBlur}
                    required={isRequired}
                />
            </div>
        </>)
}

export function InputFieldWithoutLabel({ label, type, name, id, placeholder, value, onChange, onBlur, isDisabled, maxLength, max, min, isRequired }) {
    return (
        <>
            <div>
                <input
                    type={type}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    className="input-group-text p-2"
                    value={value}
                    onChange={onChange}
                    disabled={isDisabled | false}
                    maxLength={maxLength ? maxLength : ''}
                    max={max ? max : ''}
                    min={min ? min : ''}
                    onBlur={onBlur}
                    required={isRequired}
                />
            </div>
        </>)
}

export default InputField