import React from 'react';
import InputField from '../Common/InputField';
import SelectBox from '../Common/SelectBox';
import SubmitButton from '../Common/SubmitButton';
import FormHeader from '../Common/FormHeader';

function InputForm({ formData, setFormData, onFormSubmit, onBackButtonClick }) {

    const handleSubmit = (e) => {
        e.preventDefault();

        const zipCodeRegex = /^9([0-5][0-9]{3}|6(0[0-9]{2}|1([0-5][0-9]|6[0-3])))$/
        if (!zipCodeRegex.test(formData.zipcode)) {
            document.getElementById('zipcode-error').innerHTML = "At the moment we serve customer located in Calafornia only."
        }
        else {
            document.getElementById('zipcode-error').innerHTML = ""
            onFormSubmit(formData)
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'radio') {
            if (checked) {
                setFormData(prevData => ({
                    ...prevData,
                    [name]: value,
                }));
            }
        } else {
            if (name == "zipcode" || name == "monthlybill" || name == "userannualconsumption") {
                const allowedChars = /^[0-9\s]*$/;
                if (!allowedChars.test(value)) {
                    return;
                }
            }
        }

        setFormData(e => ({
            ...e,
            [name]: value,
        }));
    };

    // const typeofroof = [
    //     { label: 'Asphalt Shingles', value: 'Asphalt Shingles' },
    //     { label: 'Tile', value: 'Tile' },
    //     { label: 'Flat roof', value: 'Flat roof' },
    //     { label: 'Metal Roof', value: 'Metal Roof' },
    // ];

    const inputtypeoptions = [
        { label: 'Avg Monthly Bill', value: 'M' },
        { label: 'Annual Consumption (kWh)', value: 'A' }
    ]

    const utilitycompany = [
        { label: 'LADWP', value: 'LADWP' },
        { label: 'Southern California Edison', value: 'Southern California Edison' },
        { label: 'Burbank water and power', value: 'Burbank water and power' },
        { label: 'Glendale water and power', value: 'Glendale water and power' },
        { label: 'Pasadena water and power', value: 'Pasadena water and power' }
    ]



    return (
        <>

            <div className='d-flex justify-content-between align-items-md-center gap-5 py-2 my-2'>
                <div className='w-100 w-md-full'>

                    <div className="bg-white shadow-custom rounded-3 w-100">
                        <form className='pt-1 px-4 pb-4' onSubmit={handleSubmit}>
                            <FormHeader heading={"Enter Home Details"} onBackButtonClick={onBackButtonClick} />

                            <div className='form-input'>
                                <InputField
                                    label="California Zip Code"
                                    type="text"
                                    name="zipcode"
                                    id="zipcode"
                                    maxLength={5}
                                    placeholder="e.g 91311"
                                    value={formData.zipcode}
                                    onChange={handleInputChange}
                                    isRequired={true}
                                />
                                <p id='zipcode-error'></p>
                            </div>

                            <div className='form-input'>
                                <SelectBox
                                    label="Utility Company"
                                    name="utilitycompany"
                                    id="utilitycompany"
                                    value={formData.utilitycompany}
                                    onChange={handleInputChange}
                                    options={utilitycompany}
                                />
                            </div>

                            <div className='form-input'>
                                <SelectBox
                                    label="Avg Monthly Bill / Annual Consumptions"
                                    name="inputtypeoptions"
                                    id="inputtypeoptions"
                                    value={formData.inputtypeoptions}
                                    onChange={handleInputChange}
                                    options={inputtypeoptions}
                                />
                            </div>

                            {formData.inputtypeoptions == 'A' ?
                                <div className='form-input'>
                                    <InputField
                                        label={inputtypeoptions?.find(option => option.value === formData.inputtypeoptions).label}
                                        type="text"
                                        name="userannualconsumption"
                                        id="userannualconsumption"
                                        placeholder="Annual Consumption in watts"
                                        value={formData.userannualconsumption}
                                        onChange={handleInputChange}
                                        min={50}
                                        isRequired={true}
                                    />
                                </div>
                                :
                                <div className='form-input'>
                                    <InputField
                                        label={inputtypeoptions?.find(option => option.value === formData.inputtypeoptions).label}
                                        name="monthlybill"
                                        type="text"
                                        id="monthlybill"
                                        placeholder="Avg. Monthly Bill $"
                                        value={formData.monthlybill}
                                        onChange={handleInputChange}
                                        min={50}
                                        max={600}
                                        isRequired={true}
                                    />
                                </div>
                            }

                            <label className="formbold-form-label">Select your roof type</label>

                            <div className="roof-options-container">
                                <div className="roof-option-item">
                                    <label htmlFor="asphaltShingles" className="roof-option">
                                        <input type="radio" id="asphaltShingles" name="typeofroof" value="Asphalt Shingles" className="radio-custom"
                                            checked={formData.typeofroof === 'Asphalt Shingles'}
                                            onChange={handleInputChange} />
                                        <img className="roof-image" src="https://images.squarespace-cdn.com/content/v1/595cf3072994ca8947944b3f/1589320910548-USW6NGQZFSQK3Q695GSS/asphalt-shake-shingles.png?format=2500w" alt="Asphalt Shingles" />
                                        Asphalt Shingles
                                    </label>
                                </div>
                                <div className="roof-option-item">
                                    <label htmlFor="tile" className="roof-option">
                                        <input type="radio" id="tile" name="typeofroof" value="Tile" className="radio-custom"
                                            checked={formData.typeofroof === 'Tile'}
                                            onChange={handleInputChange} />
                                        <img className="roof-image" src="https://www.matthewsroofing.com/wp-content/uploads/2018/01/matthews-roofing-chicago-clay-tile-roofing-system.jpg" alt="Tile" />
                                        Tile
                                    </label>
                                </div>
                                <div className="roof-option-item">
                                    <label htmlFor="flatRoof" className="roof-option">
                                        <input type="radio" id="flatRoof" name="typeofroof" value="Flat Roof" className="radio-custom"
                                            checked={formData.typeofroof === 'Flat Roof'}
                                            onChange={handleInputChange} />
                                        <img className="roof-image" src="https://21380193.fs1.hubspotusercontent-na1.net/hub/21380193/hubfs/D097C6F5-8771-42C5-AFE5-B59ACA401164.jpeg?width=1439&quality=low" alt="Flat Roof" />
                                        Flat Roof
                                    </label>
                                </div>
                                <div className="roof-option-item">
                                    <label htmlFor="metalRoof" className="roof-option">
                                        <input type="radio" id="metalRoof" name="typeofroof" value="Metal Roof" className="radio-custom"
                                            checked={formData.typeofroof === 'Metal Roof'}
                                            onChange={handleInputChange} />
                                        <img className="roof-image" src="https://lirp.cdn-website.com/78830dc0/dms3rep/multi/opt/1554931-ThinkstockPhotos-163052119-500w.jpg" alt="Metal Roof" />
                                        Metal Roof
                                    </label>
                                </div>
                            </div>





                            <SubmitButton text={"Calculate My System Size"} />

                        </form>
                    </div>
                </div>



            </div >
        </>
    )
}

export default InputForm;