import Label from '../Common/Label';
import SubmitButton from '../Common/SubmitButton';
import FormHeader from '../Common/FormHeader';
import LineGraph from '../Common/LineGraph';
import { NumberWithCommas } from '../../Utils/AddComma';

function SolarOutput({ solarData, onButtonClick, onBackButtonClick }) {

    const totalAnnualProduction = () => {
        var sum = 0
        for (let i = 0; i < solarData.solarProductionPerMonth.length; i++) {
            sum += solarData.solarProductionPerMonth[i]
        }
        return NumberWithCommas(parseInt(sum))                                                   // Converting into kWh
    }

    return (
        <>
            <div className="formbold-main-wrapper bg-white shadow-custom rounded-3 px-4 py-2">

                <div className='d-flex gap-2'>
                    <div style={{ width: "50%" }} className='w-md-full'>

                        <FormHeader heading={"Estimated System Size Information"} onBackButtonClick={onBackButtonClick} showBackButton={true} />

                        <div className='d-flex mb-5'>

                            <div className='d-flex flex-column w-50 w-md-full p-0'>
                                <Label type="label" text={"Estimated System Size"} />
                                <Label type="value" text={solarData.systemSizeWithPanels + " KW "} />

                                <Label type="label" text={"Solar Panel Watts"} />
                                <Label type="value" text={"400"} />

                            </div>

                            <div className='d-flex flex-column w-50 w-md-full p-0 '>

                                <Label type="label" text={"Number of Panels"} showToolTip={true} ToolTipText={"This assumes 400W panel"} />
                                <Label type="value" text={Math.ceil(solarData.requiredPanels)} />

                                <Label type="label" text={"Roof Area Occupied"} />
                                <Label type="value" text={(solarData.areaOccupied * 10.76391042).toFixed(2) + " sq. ft"} />

                            </div>

                        </div>

                    </div>

                    <div style={{ width: "50%" }} className='w-md-full'>
                        <FormHeader heading={"Estimated Solar Generation Versus Usage"} />
                        <div className='d-flex mb-3 gap-2'>
                            <div className='d-flex flex-column w-md-full p-0'>
                                <Label type="label" text={"Your Current Annual Electric Usage"} />
                                <Label type="value" text={NumberWithCommas(solarData.annualConsumption) + " kWh"} />
                            </div>
                            <div className='d-flex flex-column w-md-full p-0'>
                                <Label type="label" text={"Your Estimated Energy Production from Solar"} />
                                <Label type="value" text={totalAnnualProduction() + " kWh"} />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="graph-container">
                    <LineGraph solarproductions={solarData.solarProductionPerMonth} currentusage={solarData.annualConsumption} />
                </div>


                <SubmitButton onClick={onButtonClick} text={"Calculate My Cost"} />

                <div className='inputform-disclaimer'>
                    <p className='m-0 p-0'><span>*</span>This estimate is based on the information provided by the customer and is non-binding</p>
                </div>
            </div>

        </>
    )
}

export default SolarOutput