import React, { useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function LineGraph({ solarproductions, currentusage }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      let ctx = chart.ctx;
      let gradientSolar = ctx.createLinearGradient(0, 0, 0, chart.height);
      let gradientUsage = ctx.createLinearGradient(0, 0, 0, chart.height);

      // Define the gradients for Solar Production
      gradientSolar.addColorStop(0, 'rgba(180, 201, 2, 1)'); // Start color
      gradientSolar.addColorStop(1, 'rgba(255, 255, 255, 0)'); // End color

      // Define the gradients for Your Current Usage
      gradientUsage.addColorStop(0, 'rgba(27, 73, 98, 1)'); // Start color
      gradientUsage.addColorStop(1, 'rgba(255, 255, 255, 0)'); // End color

      chart.data.datasets[0].backgroundColor = gradientSolar; // Apply to Solar Production
      chart.data.datasets[1].backgroundColor = gradientUsage; // Apply to Your Current Usage

      chart.update();
    }
  }, []);

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        fill: true,
        label: 'Solar Production',
        data: solarproductions,
        borderColor: 'rgba(180, 201, 2, 1)', // Similar to gradient start
        borderWidth: 1,
        tension: 0.4,
        pointRadius: 2,
        // backgroundColor set in useEffect
      },
      {
        fill: true,
        label: 'Your Current Usage',
        data: [
          currentusage * 0.082,
          currentusage * 0.076,
          currentusage * 0.075,
          currentusage * 0.0765,
          currentusage * 0.08,
          currentusage * 0.081,
          currentusage * 0.094,
          currentusage * 0.090,
          currentusage * 0.092,
          currentusage * 0.085,
          currentusage * 0.079,
          currentusage * 0.0795,
        ],
        borderColor: 'rgba(27, 73, 98, 1)', // Similar to gradient start
        borderWidth: 1,
        tension: 0.4,
        pointRadius: 2,
        // backgroundColor set in useEffect
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value;
          }
        },
        title: {
          display: true,
          text: 'Energy (kWh)',
          align: 'center',
          rotation: 270,
          font: {
            weight: 'bold',
          },
        }
      },
      x: {
        title: {
          display: true,
          text: 'Month',
          font: {
            weight: 'bold',
          },
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top'
      }
    },
  };

  return (
    <>
      <Line ref={chartRef} data={data} options={options} height={180} />
    </>
  );
}

export default LineGraph;
