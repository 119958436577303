import React, { useState, useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';

const GoogleMapAutoFill = ({ label, type, name, id, placeholder, value, onChange, isRequired }) => {

    const [inputValue, setInputValue] = useState(value);
    const autocompleteRef = useRef(null);

    const handleSelect = (place) => {
        setInputValue(place.formatted_address);
        onChange(place);
    };

    // const apiKey = "AIzaSyCElqbjWAqk1bNymKInxu8JWn9CSwgaegE"
    const apiKey = "AIzaSyDH4uSGiB2RbErDqs9kiYacefkAYPgbSZ4"

    const options = {
        types: ['address'],
        componentRestrictions: { country: "US" },
    };

    return (
        <>
            <div>
                <label htmlFor={id} className="formbold-form-label">{label}{isRequired == true && <span className='text-danger fw-bold h6'> * </span>}</label>
                <Autocomplete
                    className="formbold-form-input"
                    apiKey={apiKey}
                    ref={autocompleteRef}
                    defaultValue={value}
                    value={inputValue}
                    onChange={onChange}
                    onSelect={handleSelect}
                    options={options}
                    placeholder="Enter Your Address"
                />
            </div>
        </>
    )
}

export default GoogleMapAutoFill