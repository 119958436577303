import '../Styles/SolarForm.css'
import Index from '../Components/SolarForm/Index'

function SolarForm() {

    return (
        <>
            <Index/>
        </>
    )
}

export default SolarForm