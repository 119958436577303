import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi2';

const popover = (ToolTipText) => (
    <Popover id="popover-basic">
        <Popover.Body>
            {ToolTipText}
        </Popover.Body>
    </Popover>
);

const ToolTip = ({ToolTipText}) => (
    <OverlayTrigger placement="top" overlay={popover(ToolTipText)}>
        <span style={{ width: "fit-content", padding: "4px" }}>
            <HiOutlineQuestionMarkCircle />
        </span>
    </OverlayTrigger>
);

export default ToolTip;