import React, { useRef, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import {
  PointElement,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  PointElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarGraph(props) {
  const chartRef = useRef(null);
  
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
      gradient.addColorStop(0, '#1B4962'); // Your color
      gradient.addColorStop(1, '#FFF'); // Black

      chart.data.datasets[0].backgroundColor = gradient;
      chart.update();
    }
  }, [props.graphData]); // Effect runs when 'graphData' changes

  const { labels, graphData } = props;

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
    legend: {
      labels: {
        fontSize: 16,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Electric Bill',
        data: graphData,
        borderColor: '#FFF',
        // backgroundColor set in useEffect
      },
    ],
  };

  return (
    <>
      <Bar
        ref={chartRef}
        options={options}
        data={data}
        height={220}
      />
    </>
  )
}

export default BarGraph;
