import React from 'react';
import InputField from '../Common/InputField';
import { useRef } from 'react';
import SubmitButton from '../Common/SubmitButton';
import FormHeader from '../Common/FormHeader';
import GoogleMapAutoFill from '../Common/GoogleMapAutoFill';


function CustomerDetails({ customerData, setCustomerData, InputData, solarOutput, onButtonClick, onBackButtonClick }) {

    const formRef = useRef();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == "phone") {
            const allowedChars = /^[0-9\s]*$/;
            if (!allowedChars.test(value)) {
                return;
            }
        }

        setCustomerData({
            ...customerData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!customerData.email.includes('@') || !customerData.email.includes('.')) {
            document.getElementById('error').innerHTML = "Invalid Email";
        }
        else if (customerData.fullname != "" && customerData.email != "" && customerData.phone != "") {
            document.getElementById('error').innerHTML = ""
            sendEmail()
            onButtonClick()
        }
        else {
            document.getElementById('error').innerHTML = "Please fill all details"
        }
    };

    const data = {
        full_name: customerData.fullname,
        email: customerData.email,
        phone: customerData.phone,
        address: customerData.address,
        zipcode: InputData.zipcode,
        monthlybill: solarOutput.monthlybill,
        type_of_roof: InputData.typeofroof,
        utilitycompany: InputData.utilitycompany,
        total_system_cost: solarOutput.totalSystemCost,
        net_cost: solarOutput.netCost,
        incentives: solarOutput.incentives,
        first_year_estimate_annual_savings: (Math.round((solarOutput.monthlybill - 24) * 12)),
        estimated_system_size: solarOutput.systemSizeWithPanels,
        number_of_panels: Math.ceil(solarOutput.requiredPanels)
    };

    const sendEmail = async () => {
        try {
            const response = await fetch("https://green360solutions.com/solar-calculator-app/backend/public/api/send-email", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const result = await response.json();
            console.log("Success:", result);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <div className="bg-white shadow-custom rounded-3 my-2">

                <form className='pt-1 px-4 pb-5' ref={formRef}>
                    <FormHeader heading={"Enter your contact information to receive a detailed system cost estimate"} onBackButtonClick={onBackButtonClick} showBackButton={true} />

                    <div className='d-flex gap-4 form-input'>
                        <div className='w-50 w-md-full'>
                            <InputField
                                label="Name"
                                type="text"
                                name="fullname"
                                id="fullname"
                                placeholder="John Smith"
                                value={customerData.fullname}
                                onChange={handleInputChange}
                                isRequired={true}
                            />
                        </div>

                        <div className='w-50 w-md-full'>
                            <InputField
                                label="Email"
                                type="email"
                                name="email"
                                id="email"
                                placeholder="example@email.com"
                                value={customerData.email}
                                onChange={handleInputChange}
                                isRequired={true}
                            />
                        </div>
                    </div>

                    <div className='d-flex gap-4 form-input'>

                        <div className='w-50 w-md-full'>
                            <GoogleMapAutoFill
                                label="Address"
                                name="address"
                                id="address"
                                placeholder="5396 North Reese Avenue, Fresno CA 93722"
                                value={customerData.address}
                                onChange={(e) => setCustomerData({ ...customerData, address: e.target.value })}
                            />
                        </div>

                        <div className='w-50 w-md-full'>
                            <InputField
                                label="Phone"
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder="5555555555"
                                value={customerData.phone}
                                isRequired={true}
                                onChange={handleInputChange}
                                minLength={10}
                                maxLength={10}
                            />
                        </div>

                    </div>
                    <div id='error-container'><p id='error'></p></div>
                    <SubmitButton onClick={handleSubmit} text={"Provide Me the System Cost"} />

                    <div className='inputform-disclaimer'>
                        <p className='m-0 p-0'><span>**</span>Your information will be kept confidential, and will not be shared with any third parties</p>
                    </div>

                </form>
            </div>



        </>
    )
}

export default CustomerDetails