import React from 'react'

function SelectBox({ label, name, id, value, onChange, options, defaultValue }) {
    return (
        <>
            <div className="formbold-mb-3">
                <label htmlFor={id} className="formbold-form-label">{label}</label>
                <select
                    className="formbold-form-input"
                    name={name}
                    id={id}
                    value={value}
                    onChange={onChange}
                    defaultValue={defaultValue}
                    required
                >
                    {options.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        </>
    )
}

export default SelectBox