import React from 'react';
import Green360Logo from '../../Assets/Green360Solutions-Logo.png';

function LogoHeader() {
    const heroStyle = {
        position: 'relative',
        height: '300px', // Adjust the height as needed
        color: 'white',
        marginBottom: '20px',
        width: '100%', // Ensure the hero section is full width
    };

    const backgroundImageStyle = {
        backgroundImage: `url(https://d2rxt25e475whq.cloudfront.net/wp-content/uploads/2023/04/25140400/AdobeStock_552326147-scaled.jpeg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
    };

    const darkOverlayStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent dark overlay
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
    };

    const textOverlayStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        color: 'white',
    };

    const contactStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0px',
    };

    // const taglineStyle = {
    //     fontSize: '16px',
    //     fontWeight: '500',
    //     fontStyle: 'oblique',
    //     color: '#1B4962',
    //     textAlign: 'center',
    //     marginTop: '5px',
    // };

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-2' style={{ width: '80%', margin: '0 auto' }}>
                <img src={Green360Logo} className='h-25 w-25 w-md-full' alt='Green360 Solutions Logo' style={{ height: '50px', width: 'auto' }} />
                <div style={contactStyle}></div>
            </div>

            <div style={heroStyle}>
                <div style={backgroundImageStyle}></div>
                <div style={darkOverlayStyle}></div>
                <div style={textOverlayStyle} className="responsive-container responsive-text">
                    <h1>See How Much It Will Cost To Install Solar Panels For Your Home</h1>
                    <p>California residents can significantly reduce their electric bills with Green360Solutions—with 0% down payment required. Discover your savings today!</p>
                </div>
            </div>

            <style jsx global>{`
                .responsive-container {
                    width: 100%;
                }

                @media only screen and (max-width: 600px) {
                    .responsive-container {
                        width: 95%;
                        padding: 0.5em;
                    }
                    .responsive-text h1 {
                        font-size: 1.2em;
                    }
                    .responsive-text p {
                        font-size: 0.9em;
                    }
                }

                @media only screen and (min-width: 601px) {
                    .responsive-container {
                        width: 35%;
                        padding: 1em;
                    }
                    .responsive-text h1 {
                        font-size: 2em;
                    }
                    .responsive-text p {
                        font-size: 0.9em;
                    }
                }
            `}</style>
        </>
    );
}

export default LogoHeader;
